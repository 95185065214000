@import url("https://fonts.googleapis.com/css?family=Roboto");

$base-color: #c1202a;

html {
  min-height: 100%;
}

html, body, #root {
  //height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Droid Sans", "Helvetica Neue", sans-serif;
}

.rc-steps-item-icon {
  background: $base-color !important;
  border-color: $base-color !important;
}

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid $base-color !important;
}

//.btn-primary {
//  background-color: $base-color !important;
//
//  &:hover, &:active, &:focus {
//    background-color: #9F1D26;
//  }
//}

.tui-image-editor-header {
  display: none !important;
  height: 0 !important;
}

.tui-image-editor-main {
  //margin-top: -62px !important;
}

.tui-image-editor-container {
  background-color: #ffffff !important;
  flex: 1 1 auto;
}

.tui-image-editor-main-container {
  background-color: #ffffff !important;
  border: none !important;
}

.tui-image-editor-container .tui-image-editor-main {
  top: 0 !important;
  right: 64px !important;
}

.tui-image-editor-controls {
  background: #ffffff !important;
  border: 1px solid rgb(235, 237, 240);
}

.tui-image-editor-submenu-style {
  background-color: #ffffff !important;
}

.tui-image-editor-button, .tui-image-editor-partition, .tui-image-editor-newline, .tui-image-editor-range-wrap {
  label {
    color: #919191 !important;
  }

  &:hover {
    label {
      color: #e9e9e9 !important;
    }
  }
}

.tui-image-editor-item .enabled {
  label {
    color: #919191 !important;
  }
}

.tui-colorpicker-palette-button {
  height: 10px;
  width: 10px;
}

.cropper-modal {
  background-color: #fff;
}

a {
  color: #dc3545;
}

a:hover {
  color: #c82333;
}

.auth-link {
  color: #c1202a;
  text-decoration: underline;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-underline {
  text-decoration: underline;
}

.gm-fullscreen-control {
  display: none;
}

.gm-svpc {
  display: none;
}
