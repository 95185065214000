.app-header {
  //border-radius: 0 0 12px 12px;
  background-color: #f7f7f7;
  z-index: 1000;

  .navbar-nav .nav-link {
    color: #343a40 !important;
    font-weight: bold;
    transition: all 0.2s ease;

    &:hover {
      color: #c1202a !important;
    }

    &.text-danger {
      color: #c1202a !important;
    }
  }

  a.nav-link.active {
    //border-bottom: 4px solid #c1202a !important;
    color: #c1202a !important;
  }
}