.auth-modal {
  .nav-tabs {
    flex-direction: row;
    margin-bottom: 16px;

    > a {
      flex: 1;
      text-align: center;
    }
  }

  .form-group.required .form-label:after {
    content: " *";
    color: #c1202a;
  }
}