@import url(https://fonts.googleapis.com/css?family=Roboto);
.auth-modal .nav-tabs {
  flex-direction: row;
  margin-bottom: 16px; }
  .auth-modal .nav-tabs > a {
    flex: 1 1;
    text-align: center; }

.auth-modal .form-group.required .form-label:after {
  content: " *";
  color: #c1202a; }

.app-header {
  background-color: #f7f7f7;
  z-index: 1000; }
  .app-header .navbar-nav .nav-link {
    color: #343a40 !important;
    font-weight: bold;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .app-header .navbar-nav .nav-link:hover {
      color: #c1202a !important; }
    .app-header .navbar-nav .nav-link.text-danger {
      color: #c1202a !important; }
  .app-header a.nav-link.active {
    color: #c1202a !important; }

html {
  min-height: 100%; }

html, body, #root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }

body {
  margin: 0;
  padding: 0;
  font-family: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Droid Sans", "Helvetica Neue", sans-serif; }

.rc-steps-item-icon {
  background: #c1202a !important;
  border-color: #c1202a !important; }

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid #c1202a !important; }

.tui-image-editor-header {
  display: none !important;
  height: 0 !important; }

.tui-image-editor-container {
  background-color: #ffffff !important;
  flex: 1 1 auto; }

.tui-image-editor-main-container {
  background-color: #ffffff !important;
  border: none !important; }

.tui-image-editor-container .tui-image-editor-main {
  top: 0 !important;
  right: 64px !important; }

.tui-image-editor-controls {
  background: #ffffff !important;
  border: 1px solid #ebedf0; }

.tui-image-editor-submenu-style {
  background-color: #ffffff !important; }

.tui-image-editor-button label, .tui-image-editor-partition label, .tui-image-editor-newline label, .tui-image-editor-range-wrap label {
  color: #919191 !important; }

.tui-image-editor-button:hover label, .tui-image-editor-partition:hover label, .tui-image-editor-newline:hover label, .tui-image-editor-range-wrap:hover label {
  color: #e9e9e9 !important; }

.tui-image-editor-item .enabled label {
  color: #919191 !important; }

.tui-colorpicker-palette-button {
  height: 10px;
  width: 10px; }

.cropper-modal {
  background-color: #fff; }

a {
  color: #dc3545; }

a:hover {
  color: #c82333; }

.auth-link {
  color: #c1202a;
  text-decoration: underline;
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer !important; }

.text-underline {
  text-decoration: underline; }

.gm-fullscreen-control {
  display: none; }

.gm-svpc {
  display: none; }

